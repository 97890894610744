import { NextPage } from 'next'
import Head from 'next/head'
import NextLink from 'next/link'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { ContainerSlim } from '@components/styled'
import { H2 } from '@components/Heading'
import Link from '@components/Link'

const Columns = styled.div`
  @media screen and (min-width: 1260px) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
`

const Title = styled(H2).attrs({ as: 'h1' })`
  margin: 0 0 4rem;

  ${Link} {
    font-weight: 200;
  }
`

const Gif = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 36rem;

  ${up('md')} {
    max-width: 100%;
  }

  @media screen and (min-width: 1260px) {
    transform: scale(1.4);
  }
`

const Custom404Page: NextPage = () => (
  <>
    <Head>
      <title>404 - Page not found</title>
    </Head>

    <ContainerSlim>
      <Columns>
        <Title>
          Page not found,
          <br />
          <NextLink passHref href="/">
            <Link>back to home</Link>
          </NextLink>
        </Title>

        <Gif src="/images/error-page/travolta.gif" alt="" />
      </Columns>
    </ContainerSlim>
  </>
)

export default Custom404Page
